import { Button, Switch, Typography } from "@mui/material"
import { addMinutes, format } from "date-fns"
import { useField } from "formik"
import { FC, useEffect, useState } from "react"
import { BiDotsHorizontalRounded, BiEdit } from "react-icons/bi"
import { ScheduledBreak } from "../../../graphql/generated/client-types-and-hooks"
import { useModalProps } from "../../../components/Modals/hooks/useModalProps"
import { MenuItem, QuickMenuMui } from "../../../components/QuickMenuMui"
import { isEditingAnExistingScheduleType, isNewScheduleType } from "../utils/helpers"
import { H4 } from "../../../components/deprecated"
import { sortByTime } from "../../../helpers/dateAndTime/time-utility"
import { IconButton } from "../../../components/IconButton"
import { ScheduleBreakExpectations } from "../../../components/Partials/Drawer/components/AddOrEditScheduleDrawer"
import { ScheduledBreakCreateOrEditModal } from "../../../components/Partials/ScheduledBreakCreateOrEditModal"

export const WorkDayBreaks: FC<{
  fieldName: string
  isNewProject: boolean
  projectId?: string
  scheduledBreaks?: ScheduleBreakExpectations[]
}> = ({ fieldName, isNewProject = false, projectId, scheduledBreaks }) => {
  const [{ value: breaks }, _errors, helpers] = useField<ScheduleBreakExpectations[]>(fieldName)
  const modalProps = useModalProps("Workday Breaks")

  const [breakToEdit, setBreakToEdit] = useState<ScheduleBreakExpectations | undefined>(undefined)

  const toggleActive = (id: string, index: number) => {
    let updated = [...breaks]

    if (!!id) {
      updated = breaks.map((sb) => (sb.id === id ? { ...sb, isActive: !sb.isActive } : sb))
    } else if (index >= 0) {
      const breakToToggle = updated[index]
      breakToToggle.isActive = !breakToToggle.isActive
    }

    helpers.setValue(updated)
  }

  const onClose = () => {
    setBreakToEdit(undefined)
    modalProps.handleCloseModal()
  }

  const onAddScheduledBreak = (
    scheduledBreak:
      | ScheduleBreakExpectations
      | {
          durationInMinutes: number
          localizedStartTime: string
          name: string
          breakTaskId: string | undefined
          isUnpaid: boolean
        }
  ) => {
    if (Boolean(breakToEdit)) {
      const breakToEditIndex = breaks.findIndex((sb) => sb === breakToEdit)

      if (breakToEditIndex >= 0) {
        const updatedBreaks = [...breaks]
        updatedBreaks.splice(breakToEditIndex, 1, scheduledBreak as ScheduledBreak)
        helpers.setValue(updatedBreaks)
      }

      onClose()
    } else if (isEditingAnExistingScheduleType(scheduledBreak)) {
      const updated = breaks.map((sb) => (sb.id === scheduledBreak?.id ? scheduledBreak : sb))

      helpers.setValue(updated)

      onClose()
    } else if (isNewScheduleType(scheduledBreak)) {
      const newBreak: ScheduleBreakExpectations = {
        ...scheduledBreak,
        breakTask: {
          id: scheduledBreak?.breakTaskId ?? "",
          name: scheduledBreak.name,
          isUnpaid: scheduledBreak?.isUnpaid || scheduledBreak.breakTask?.isUnpaid || false,
          projectId: projectId || "",
        },
        id: "",
        isActive: true,
      }

      helpers.setValue([...breaks, newBreak])

      modalProps.handleCloseModal()
    }
  }

  useEffect(() => {
    if (scheduledBreaks?.length) {
      const sortedBreaks = scheduledBreaks.sort((a, b) => sortByTime(a.localizedStartTime, b.localizedStartTime))
      helpers.setValue(sortedBreaks)
    }
  }, [scheduledBreaks, helpers])

  return (
    <div className="w-full">
      <div className="flex justify-between items-baseline gap-x-2">
        <H4>Workday Breaks</H4>
        <Button variant="text" color="primary" onClick={modalProps.handleOpenModal}>
          Add
        </Button>
      </div>
      <div className="flex-col gap-y-4">
        {breaks?.map((sb, index) => {
          const [hours, minutes] = sb.localizedStartTime.split(":")
          const startDate = new Date(new Date().setHours(+hours, +minutes))
          const endDate = addMinutes(startDate, sb.durationInMinutes)

          const startTime = format(startDate, "h:mm a")
          const endTime = format(endDate, "h:mm a")

          const menuItems: MenuItem[][] = [
            [
              {
                value: "Edit",
                onClick: () => {
                  setBreakToEdit(sb)
                  modalProps.handleOpenModal()
                },
                Icon: BiEdit,
              },
            ],
          ]

          return (
            <div key={index} className="flex items-center gap-x-2">
              <Switch checked={sb.isActive} onChange={() => toggleActive(sb.id, index)} />
              <Typography>{sb.name}</Typography>
              <Typography color="GrayText">
                {startTime} - {endTime}
              </Typography>
              <QuickMenuMui items={menuItems}>
                <IconButton Icon={BiDotsHorizontalRounded} className="size-6" />
              </QuickMenuMui>
            </div>
          )
        })}
      </div>

      {breaks?.length ? <></> : <Typography color="GrayText">No workday breaks set</Typography>}

      {modalProps.isOpen && (
        <ScheduledBreakCreateOrEditModal
          isNewProject={isNewProject}
          modalProps={{
            ...modalProps,
            handleCloseModal: onClose,
            isOpen: true,
          }}
          onConfirm={onAddScheduledBreak}
          projectId={projectId}
          scheduledBreak={breakToEdit}
        />
      )}
    </div>
  )
}
