import { MenuItem } from "@mui/material"
import { format, parse } from "date-fns"
import { Form, Formik } from "formik"
import { FC } from "react"
import * as Yup from "yup"
import { ScheduledBreak, TaskVisibilityLevel } from "../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../graphql/generated/gql"
import { ModalProps } from "../Modals/hooks/useModalProps"
import { PickPlus } from "../../types/helpers"
import { CreatableAutocomplete, CreatableAutocompleteOption } from "../Formik/CreatableAutocomplete"
import { DigitalClockTimePicker } from "../Formik/DigitalClockTimePicker"
import { TextField } from "../Formik/TextField"
import { MuiModal } from "../Modals/components/Elements/MuiModal"
import { useQuery } from "urql"

const BreakTasks = graphql(`
  query CreateOrEditScheduledBreak($projectId: String, $visibilityLevel: TaskVisibilityLevel) {
    tasks(projectId: $projectId, visibilityLevel: $visibilityLevel) {
      id
      name
      isUnpaid
      projectId
      visibilityLevel
    }
  }
`)

const defaultBreakNames = ["Paid Break", "Unpaid Break"]

type BreakExpectations = PickPlus<
  ScheduledBreak,
  "id" | "durationInMinutes" | "localizedStartTime" | "name" | "isActive" | "breakTaskId"
> & {
  breakTask?: PickPlus<ScheduledBreak["breakTask"], "id" | "name" | "isUnpaid" | "projectId">
}

export const ScheduledBreakCreateOrEditModal: FC<{
  isNewProject?: boolean
  modalProps: ModalProps
  projectId?: string
  scheduledBreak?: BreakExpectations
  onConfirm: (
    newBreak:
      | BreakExpectations
      | {
          durationInMinutes: number
          localizedStartTime: string
          name: string
          breakTaskId: string
          isUnpaid: boolean
        }
  ) => void
}> = ({ isNewProject = false, modalProps, onConfirm, projectId = undefined, scheduledBreak }) => {
  const [{ data }] = useQuery({
    query: BreakTasks,
    variables: { projectId, visibilityLevel: TaskVisibilityLevel.Hidden },
    pause: isNewProject,
  })

  const defaultBreakTasks = [
    { id: "paidBreak", name: "Paid Break", isUnpaid: false },
    { id: "unpaidBreak", name: "Unpaid Break", isUnpaid: true },
  ]

  const breakTasks = data?.tasks ?? defaultBreakTasks

  const autocompleteOptions: CreatableAutocompleteOption[] = ["Lunch", "Break"].map((name) => ({
    label: name,
    id: name,
  }))

  if (scheduledBreak) {
    if (!defaultBreakNames.includes(scheduledBreak.name))
      autocompleteOptions.push({ label: scheduledBreak.name, id: scheduledBreak.name })
  }

  const scheduledBreakTaskId = scheduledBreak?.breakTask?.id || (scheduledBreak?.breakTaskId ?? "")
  const initialScheduledBreakTask =
    breakTasks.find((t) => t.id === scheduledBreakTaskId) ??
    breakTasks.find((t) => t.isUnpaid === scheduledBreak?.breakTask?.isUnpaid) ??
    defaultBreakTasks.find((t) => t.isUnpaid)
  const initialIsUnpaid = initialScheduledBreakTask?.isUnpaid ?? true

  return (
    <Formik<{
      id?: string
      name: string
      localizedStartTime: Date | ""
      durationInMinutes: string
      breakTaskId: string | undefined
      isUnpaid: boolean
    }>
      enableReinitialize
      initialValues={{
        ...scheduledBreak,
        name: scheduledBreak?.name ?? "",
        localizedStartTime: scheduledBreak?.localizedStartTime
          ? parse(scheduledBreak.localizedStartTime, "HH:mm", new Date())
          : "",
        durationInMinutes: scheduledBreak?.durationInMinutes.toString() ?? "",
        breakTaskId: initialScheduledBreakTask?.id ?? "",
        isUnpaid: initialIsUnpaid,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
        localizedStartTime: Yup.date().required("Required"),
        durationInMinutes: Yup.number().required("Required"),
        breakTaskId: Yup.string()
          .oneOf(
            breakTasks.map((t) => t.id),
            "Please select a task"
          )
          .required("Required"),
        isUnpaid: Yup.boolean().default(true),
      })}
      onSubmit={(
        { name, localizedStartTime, durationInMinutes, breakTaskId = "unpaidBreak", isUnpaid },
        { setFieldError }
      ) => {
        if (localizedStartTime === "") {
          setFieldError("localizedStartTime", "Empty string not valid")
          return
        }

        if (scheduledBreak) {
          const breakTask = breakTasks.find((t) => t.id === breakTaskId)
          return onConfirm({
            ...scheduledBreak,
            durationInMinutes: parseInt(durationInMinutes),
            localizedStartTime: format(localizedStartTime, "HH:mm"),
            name,
            breakTaskId,
            isUnpaid,
            breakTask: breakTask ? { ...breakTask, projectId: projectId ?? "" } : undefined,
          })
        }

        return onConfirm({
          durationInMinutes: parseInt(durationInMinutes),
          localizedStartTime: format(localizedStartTime, "HH:mm"),
          name,
          breakTaskId,
          isUnpaid,
        })
      }}
    >
      {({ submitForm, resetForm, setFieldValue }) => {
        return (
          <MuiModal
            isOpen={modalProps.isOpen}
            handleCloseModal={() => {
              resetForm()
              modalProps.handleCloseModal()
            }}
            contentLabel={scheduledBreak ? "Edit break" : "Add organization break"}
            submitButtonText="Save"
            submitForm={submitForm}
          >
            <Form className="flex flex-col gap-2">
              <CreatableAutocomplete
                defaultValue={scheduledBreak?.name}
                required
                name="name"
                label="Break name"
                options={autocompleteOptions}
              />
              <TextField
                select
                name="breakTaskId"
                label="Task"
                onChange={(e) => {
                  const breakTaskId = e.target.value
                  const breakTask = breakTasks.find((t) => t.id === breakTaskId)
                  setFieldValue("isUnpaid", breakTask?.isUnpaid)
                  setFieldValue("breakTaskId", breakTaskId)
                }}
                required
              >
                {breakTasks.map((task, i) => (
                  <MenuItem key={task?.id || i} value={task?.id}>
                    {task.name}
                  </MenuItem>
                ))}
              </TextField>
              <div className="flex flex-row gap-4 md:min-w-[400px]">
                <DigitalClockTimePicker className="w-1/2" label="Start time" name="localizedStartTime" required />
                <TextField className="w-1/2" name="durationInMinutes" label="Duration" select required>
                  <MenuItem value={15}>15 minutes</MenuItem>
                  <MenuItem value={30}>30 minutes</MenuItem>
                  <MenuItem value={45}>45 minutes</MenuItem>
                  <MenuItem value={60}>1 hour</MenuItem>
                </TextField>
              </div>
            </Form>
          </MuiModal>
        )
      }}
    </Formik>
  )
}
