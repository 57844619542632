import { addDays, differenceInCalendarDays, eachDayOfInterval, isEqual, isWithinInterval } from "date-fns"
import { ScheduleWorkDay } from "../../../graphql/generated/client-types-and-hooks"
import { Values } from "../../../pages/projects/create/_index"
import { NonWorkDays } from "../../../components/Partials/Organizations/TabPanels/ScheduleSubPanels/ScheduleDetails"
import { ScheduleBreakExpectations } from "../../../components/Partials/Drawer/components/AddOrEditScheduleDrawer"

/**
 *
 * @param workDays Days of the week people will be working on the project / task
 * @param startDate The starting day of the project / task
 * @param endDate The end date of the project / task
 * @param nonWorkDays Holidays and days off for the project / task schedule
 * @returns The calculated number of working days for the project / task
 */
export const calculateNumberOfWorkingDays = (
  workDays: ScheduleWorkDay[],
  startDate: Date | null,
  endDate: Date | null,
  nonWorkDays: Date[]
) => {
  if (!startDate || !endDate) {
    return
  }

  const totalDaysInRange = differenceInCalendarDays(endDate, startDate) + 1 // add 1 for the start date

  // get the day-of-week index for each working day (e.g. M - F is [1,2,3,4,5])
  const workingDaysOfTheWeek = getWorkingDaysOfTheWeek(workDays)

  // https://stackoverflow.com/questions/21985259/how-many-specific-days-within-a-date-range-in-javascript/22122796#22122796
  // https://stackoverflow.com/questions/25562173/calculate-number-of-specific-weekdays-between-dates
  let result = workingDaysOfTheWeek.reduce((totalDaysWorked, workingDay) => {
    const dayOfTheWeekIndex = (startDate.getDay() + 6 - workingDay) % 7
    const totalCountOfDaysForThisDayOfTheWeek = Math.floor((totalDaysInRange + dayOfTheWeekIndex) / 7)

    return totalDaysWorked + totalCountOfDaysForThisDayOfTheWeek
  }, 0)

  if (nonWorkDays?.length) {
    const numberOfHolidaysInWorkingPeriod = nonWorkDays.reduce((total, nonWorkDay) => {
      const holidayIsOnWorkingDay = workingDaysOfTheWeek.some((day) => day === nonWorkDay.getDay())
      const holidayIsInDateRange = isWithinInterval(nonWorkDay, { start: startDate, end: endDate })

      return holidayIsOnWorkingDay && holidayIsInDateRange ? (total += 1) : total
    }, 0)

    result -= numberOfHolidaysInWorkingPeriod
  }

  return result
}

export type TaskWithDates = {
  startDate?: Date | null
  endDate?: Date | null
  [key: string]: unknown
}
export const getSumOfTaskWorkDays = (
  tasks: TaskWithDates[] | undefined,
  workDays: ScheduleWorkDay[],
  nonWorkDays: Date[]
) => {
  if (!tasks || !workDays || !nonWorkDays) return
  let totalDuration = 0
  tasks?.forEach((task) => {
    if (task.startDate && task.endDate) {
      const taskDuration =
        calculateNumberOfWorkingDays(
          workDays,
          new Date(task.startDate),
          new Date(task.endDate),
          nonWorkDays.map((nd) => new Date(nd))
        ) || 0
      totalDuration += taskDuration
    }
  })

  return totalDuration
}

/**
 *
 * @param numberOfWorkingDays total number of days working on the project or task
 * @param startDate The project / task start date
 * @param workDays The days of the week people will be working on the project / task
 * @param nonWorkDays Holidays and other days off from the project
 * @returns The date of the last working day of the project / task
 */
export const calculateEndDate = (
  numberOfWorkingDays: number | null,
  startDate: Date | null,
  workDays: ScheduleWorkDay[],
  nonWorkDays?: Date[]
) => {
  if (!numberOfWorkingDays || !startDate) {
    return null
  }

  let daysToAddFromStartDate = 0
  let workDaysAdded = 0
  const workingDaysOfTheWeek = getWorkingDaysOfTheWeek(workDays)

  while (workDaysAdded < numberOfWorkingDays) {
    const date = addDays(startDate, daysToAddFromStartDate)
    const dayOfWeekIndex = date.getDay()
    const isHoliday = nonWorkDays?.some((nonWorkDay) => isEqual(nonWorkDay, date))
    const isWorkDay = workingDaysOfTheWeek.some((day) => day === dayOfWeekIndex)

    if (isWorkDay && !isHoliday) {
      workDaysAdded++
    }

    daysToAddFromStartDate++
  }

  const newEndDate = addDays(startDate, daysToAddFromStartDate - 1)

  return newEndDate
}

/**
 *
 * @param workDays An array of WorkDay
 * @returns An array with each day of week index for each active work day
 */
const getWorkingDaysOfTheWeek = (workDays: ScheduleWorkDay[]) => {
  const workingDaysOfTheWeek = workDays.reduce((arr: number[], day, index) => {
    if (day.active) {
      arr.push(index)
    }

    return arr
  }, [])

  return workingDaysOfTheWeek
}

/**
 *
 * @param nonWorkDays An array of nonWorkDays on a schedule
 * @returns An array of dates for every non work day in a date range
 */
export const getExpandedNonWorkDays = (nonWorkDays: NonWorkDays) => {
  const expandedNonWorkingDays: Date[] = []

  nonWorkDays
    ?.filter((nwd) => nwd.active)
    ?.forEach((nonWorkDay) => {
      const [start, end] = nonWorkDay.dateRange
      expandedNonWorkingDays.push(...eachDayOfInterval({ start, end }))
    })

  // Removes duplicates if holiday is one day or if holidays overlap
  const uniqueDates = [...new Set(expandedNonWorkingDays)]
  return uniqueDates
}

/**
 * Function to check if a scheduled break is being edited or not.
 * @param scheduledBreak The scheduled break to check
 * @returns true if the scheduled break is being edited, false if it is new
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEditingAnExistingScheduleType = (scheduledBreak: any): scheduledBreak is ScheduleBreakExpectations => {
  return Boolean(scheduledBreak?.breakTask)
}

/**
 * Function to check if a scheduled break is new or not. This is safer than checking !isEditingScheduleType because that function could give a false positive if the scheduled break is missing a breakTaskId.
 * @param scheduledBreak The scheduled break to check
 * @returns true if the scheduled break is new, false if it is being edited
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNewScheduleType = (scheduledBreak: any): scheduledBreak is ScheduleBreakExpectations => {
  return !Boolean(scheduledBreak?.breakTask) && Boolean(scheduledBreak?.breakTaskId)
}

/**
 * Function to calculate a projects start and end dates given the start dates and work days for individual tasks
 * @param tasks an array of tasks with start dates and work days
 * @param workDays Days of the week people will be working on the project / task
 * @param nonWorkDays Holidays and days off for the project / task schedule
 * @returns the start and end date for a project
 */

export const calculateEarliestAndLatestDates = (
  tasks: Values["tasks"],
  workDays: ScheduleWorkDay[],
  nonWorkingDays: Date[]
) => {
  let earliestStartDate = null
  let latestEndDate = null

  for (const task of tasks) {
    if (task.startDate) {
      const taskStartDate = new Date(task.startDate)
      if (!earliestStartDate || taskStartDate < earliestStartDate) {
        earliestStartDate = taskStartDate
      }

      if (task.workDays) {
        const taskEndDate = calculateEndDate(task.workDays, taskStartDate, workDays || [], nonWorkingDays)
        if (taskEndDate && (!latestEndDate || taskEndDate > latestEndDate)) {
          latestEndDate = taskEndDate
        }
      }
    }
  }

  return { earliestStartDate, latestEndDate }
}

//temporary until we decide how to deal with orgs that don't have work days
export const defaultWorkDays = [
  {
    label: "Sun",
    active: true,
  },
  {
    label: "Mon",
    active: true,
  },
  {
    label: "Tue",
    active: true,
  },
  {
    label: "Wed",
    active: true,
  },
  {
    label: "Thur",
    active: true,
  },
  {
    label: "Fri",
    active: true,
  },
  {
    label: "Sat",
    active: true,
  },
]
