import { FC } from "react"
import { Schedule, ScheduledBreak } from "../../../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../../../types/helpers"
import { LabeledSection } from "../../../../LabeledSection"
import { ValueOrNoneLabel } from "../../../Assets/BasicInfo"
import { ChipForScheduleDetails, getBreakLabel, getFormattedTime, getNonWorkDayLabel } from "./scheduleDetailsHelpers"
import { getDuration } from "../../../../../helpers/dateAndTime/time-utility"

export type WorkDays = Schedule["workDays"]
export type WorkHours = Schedule["workHours"]
export type NonWorkDays = Schedule["nonWorkDays"]

export type ScheduleBreak = PickPlus<
  ScheduledBreak,
  "id" | "durationInMinutes" | "isActive" | "localizedStartTime" | "name" | "breakTaskId"
> & { breakTask: PickPlus<ScheduledBreak["breakTask"], "id" | "name" | "isUnpaid" | "projectId"> }

export const ScheduleDetails: FC<{
  schedule?: Schedule
  scheduledBreaks?: ScheduleBreak[]
  startDate?: Date | null
  endDate?: Date | null
  estimatedHours?: number | string | null
  showDaysDatesAndManHours?: boolean
}> = ({ schedule, scheduledBreaks, startDate, endDate, estimatedHours, showDaysDatesAndManHours = false }) => {
  return (
    <div className="flex-col grid gap-y-4">
      {showDaysDatesAndManHours && (
        <ProjectScheduleLabels startDate={startDate} endDate={endDate} estimatedHours={estimatedHours} />
      )}

      <WorkingDays days={schedule?.workDays} />

      <WorkdayHours workHours={schedule?.workHours} />

      <WorkDayBreaks scheduledBreaks={scheduledBreaks || []} />

      <NonWorkDays nonWorkDays={schedule?.nonWorkDays} />
    </div>
  )
}

export const ProjectScheduleLabels: FC<{
  startDate?: Date | null
  endDate?: Date | null
  workDays?: number | null
  duration?: number | null
  estimatedHours?: number | string | null
}> = ({ startDate, endDate, estimatedHours, workDays, duration }) => {
  return (
    <div className="mb-1 flex-col grid gap-y-4">
      <LabeledSection label="Start & End Date">
        <ValueOrNoneLabel
          value={`${startDate?.toLocaleDateString() || "None"} – ${endDate?.toLocaleDateString() || "None"}`}
        />
      </LabeledSection>
      <LabeledSection label="Duration">
        <ValueOrNoneLabel value={duration && `${duration} days`} />
      </LabeledSection>
      <LabeledSection label="Work Days">
        <ValueOrNoneLabel value={workDays && `${workDays} days`} />
      </LabeledSection>
      <LabeledSection label="Man-Hours">
        <ValueOrNoneLabel value={estimatedHours} />
      </LabeledSection>
    </div>
  )
}

const WorkingDays: FC<{ days?: WorkDays }> = ({ days }) => {
  const activeDays = days?.filter((day) => day.active)

  return (
    <LabeledSection label="Working Days">
      {activeDays?.length ? (
        activeDays.map((day, i) => <ChipForScheduleDetails key={`${i}-${day}`} label={day.label} />)
      ) : (
        <ChipForScheduleDetails label="None" />
      )}
    </LabeledSection>
  )
}

const WorkdayHours: FC<{ workHours?: WorkHours }> = ({ workHours }) => {
  const startTime = getFormattedTime(workHours?.startTime)
  const endTime = getFormattedTime(workHours?.endTime)

  const [startHours, startMinutes] = workHours?.startTime?.split(":") || []
  const [endHours, endMinutes] = workHours?.endTime?.split(":") || []

  const { hours, minutes } = getDuration(
    new Date(0, 0, 0, +startHours, +startMinutes),
    new Date(0, 0, 0, +endHours, +endMinutes)
  )

  return (
    <LabeledSection label="Workday Hours">
      {!workHours?.hours ? (
        <ChipForScheduleDetails label="None" />
      ) : (
        <>
          <ChipForScheduleDetails label="Start Time" content={startTime} />
          <ChipForScheduleDetails label="End Time" content={endTime} />
          <ChipForScheduleDetails label="Total Paid Hours" content={`${hours}h ${minutes}m`} />
        </>
      )}
    </LabeledSection>
  )
}
const NonWorkDays: FC<{ nonWorkDays?: NonWorkDays }> = ({ nonWorkDays }) => {
  const active = nonWorkDays?.filter((day) => day.active)

  return (
    <LabeledSection label="Non-Workdays">
      {!active?.length ? (
        <ChipForScheduleDetails label="None" />
      ) : (
        active.map((nonWorkDay) => (
          <ChipForScheduleDetails key={`nwd-${nonWorkDay.id}`} label={getNonWorkDayLabel(nonWorkDay)} />
        ))
      )}
    </LabeledSection>
  )
}

const WorkDayBreaks: FC<{ scheduledBreaks: ScheduleBreak[] }> = ({ scheduledBreaks }) => {
  const active = scheduledBreaks?.filter((sb) => sb.isActive)
  return (
    <LabeledSection label="Workday Breaks">
      {!active?.length ? (
        <ChipForScheduleDetails label="None" />
      ) : (
        active.map((sb) => <ChipForScheduleDetails key={`sb-${sb.id}`} label={getBreakLabel(sb)} />)
      )}
    </LabeledSection>
  )
}
