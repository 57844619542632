import { Button, Container, Typography } from "@mui/material"
import { format } from "date-fns"
import { Formik, FormikValues } from "formik"
import { FC, useContext } from "react"
import * as Yup from "yup"
import { NonWorkDays } from "../../../../features/Scheduling/components/NonWorkDays"
import { WeekDayToggleButtons } from "../../../../features/Scheduling/components/WeekDayToggleButtons"
import { WorkDayBreaks } from "../../../../features/Scheduling/components/WorkDayBreaks"
import { WorkDayHours } from "../../../../features/Scheduling/components/WorkDayHours"
import { Schedule, ScheduledBreak } from "../../../../graphql/generated/client-types-and-hooks"
import { ScheduleBreakCreateType } from "../../../../pages/projects/create/_index"
import { PickPlus } from "../../../../types/helpers"
import { H4 } from "../../../deprecated"
import { WorkHours } from "../../Organizations/TabPanels/ScheduleSubPanels/ScheduleDetails"
import { SingleDrawerContext } from "./Elements/Drawer"
import { DrawerBody } from "./Elements/DrawerBody"
import { DrawerFooter } from "./Elements/DrawerFooter"
import { DrawerHeader } from "./Elements/DrawerHeader"

export type ScheduleBreakExpectations = PickPlus<
  ScheduledBreak,
  "id" | "durationInMinutes" | "localizedStartTime" | "name" | "isActive" | "breakTaskId"
> & { breakTask?: PickPlus<ScheduledBreak["breakTask"], "id" | "name" | "isUnpaid" | "projectId"> }

const getInitialWorkHours = (workHours: WorkHours) => {
  if (!workHours?.startTime || !workHours?.endTime) {
    return { hours: null, startTime: null, endTime: null }
  }

  if (typeof workHours.startTime !== "string") {
    workHours.startTime = format(workHours.startTime, "hh:mm")
  }

  const [startHours, startMinutes] = workHours?.startTime?.split(":")
  const startDate = new Date(new Date().setHours(+startHours, +startMinutes))

  if (typeof workHours.endTime !== "string") {
    workHours.endTime = format(workHours.endTime, "hh:mm")
  }

  const [endHours, endMinutes] = workHours?.endTime?.split(":")
  const endDate = new Date(new Date().setHours(+endHours, +endMinutes))

  return { ...workHours, startTime: startDate, endTime: endDate }
}

export const AddOrEditScheduleDrawer: FC<{
  isNewProject?: boolean
  projectId?: string
  schedule?: Schedule
  scheduledBreaks?: ScheduleBreakExpectations[]
  handleSave: (schedule: Schedule, scheduledBreaks: ScheduleBreakCreateType[]) => void
}> = ({ isNewProject = false, projectId, schedule, scheduledBreaks, handleSave }) => {
  const workDayBreaks = scheduledBreaks || []

  const { handleClose } = useContext(SingleDrawerContext)

  const handleSubmit = (values: FormikValues) => {
    const { startTime, endTime } = values.workHours

    const workHours = {
      ...values.workHours,
      startTime: startTime ? format(values.workHours.startTime, "HH:mm") : "",
      endTime: endTime ? format(values.workHours.endTime, "HH:mm") : "",
    }

    const editedSchedule: Schedule = {
      id: schedule?.id || "",
      isDefault: false,
      nonWorkDays: values.nonWorkDays,
      workDays: values.workDays,
      workHours,
    }

    handleSave(editedSchedule, values.workDayBreaks)
    handleClose()
  }

  return (
    <div className="pb-10">
      <DrawerHeader />

      <Formik
        enableReinitialize
        initialValues={{
          id: schedule?.id ?? undefined,
          isDefault: schedule?.isDefault || false,
          workDays: schedule?.workDays || [],
          workHours: getInitialWorkHours(schedule?.workHours),
          nonWorkDays: schedule?.nonWorkDays || [],
          workDayBreaks,
        }}
        validationSchema={Yup.object().shape({
          id: Yup.string().nullable(),
          isDefault: Yup.boolean().nullable(),
          workDays: Yup.array()
            .of(
              Yup.object().shape({
                label: Yup.string(),
                active: Yup.bool(),
              })
            )
            .test("validWorkDays", "Select at least 1 work day", (workDays) => workDays?.some((w) => w.active === true))
            .required("required"),
          nonWorkDays: Yup.array()
            .of(
              Yup.object().shape({
                id: Yup.string(),
                name: Yup.string(),
                dateRange: Yup.array().of(Yup.date()),
                active: Yup.boolean(),
              })
            )
            .notRequired(),
          workHours: Yup.object().shape({
            hours: Yup.number().notRequired(),
            startTime: Yup.date().notRequired(),
            endTime: Yup.date().notRequired(),
          }),
          workDayBreaks: Yup.array().of(
            Yup.object().shape({
              id: Yup.string(),
              durationInMinutes: Yup.number(),
              isActive: Yup.boolean(),
              localizedStartTime: Yup.string(),
              name: Yup.string(),
              breakTask: Yup.object().shape({
                id: Yup.string(),
                name: Yup.string(),
              }),
            })
          ),
        })}
        onSubmit={handleSubmit}
        validateOnBlur
        validateOnChange={false}
      >
        {({ submitForm }) => {
          return (
            <>
              <DrawerBody>
                <Typography variant="h5" fontSize={32}>
                  New Work Schedule Template
                </Typography>

                <Container className="mt-12 ml-0 pl-0 w-full" maxWidth="sm">
                  <section className="flex-col gap-x-2 gap-y-4 mt-8 border p-8 w-full">
                    <div className="flex gap-x-2 items-center">
                      <H4>Working Days</H4>
                    </div>
                    <div className="flex gap-x-2 gap-y-4">
                      <WeekDayToggleButtons name="workDays" />
                    </div>
                  </section>

                  <section className="flex gap-x-2 p-8 my-8 border w-full">
                    <NonWorkDays fieldName="nonWorkDays" />
                  </section>

                  <section className="flex gap-x-2 p-8 my-8 border w-full">
                    <WorkDayBreaks
                      fieldName="workDayBreaks"
                      isNewProject={isNewProject}
                      scheduledBreaks={scheduledBreaks}
                      projectId={projectId}
                    />
                  </section>

                  <section className="flex gap-x-2 gap-y-4">
                    <WorkDayHours name="workHours" />
                  </section>
                </Container>
              </DrawerBody>

              <DrawerFooter>
                <div className="pl-8">
                  <Button variant="contained" color="primary" onClick={submitForm}>
                    Save schedule
                  </Button>
                  <Button color="primary" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </DrawerFooter>
            </>
          )
        }}
      </Formik>
    </div>
  )
}
